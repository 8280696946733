<template>
  <div class="rganization">
    <div class="content">
      <!-- <div class="tit">
        <el-select class="box one" v-model="value" placeholder="请选择检察院">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
        <el-select class="box two" v-model="value" placeholder="请选择案件类型">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
        <el-select class="box two" v-model="value" placeholder="请选择审核状态">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
        <el-select class="box two" v-model="value" placeholder="请选择审核人员">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
        <el-input class="box four" placeholder="请输入手机号码" v-model="input" clearable> </el-input>
        <el-date-picker class="box five" v-model="value2" type="datetimerange" align="right" start-placeholder="开始日期" end-placeholder="结束日期" :default-time="['12:00:00', '08:00:00']"></el-date-picker>
        <el-button class="box submit" type="primary" icon="el-icon-search">搜索</el-button>
      </div> -->
      <div class="table">
        <el-tabs v-model="activeName">
          <el-tab-pane label="证件类型" name="1">
            <el-table
              ref="multipleTable"
              :data="tableData"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="{textAlign:'center'}"
              :cell-style="{textAlign:'center'}">
              <!-- <el-table-column
                type="selection"
                width="55">
              </el-table-column> -->
              <el-table-column
                label="字典编号"
                width="120">
                <template slot-scope="scope">{{ scope.row.id }}</template>
              </el-table-column>
              <el-table-column
                prop="cardname"
                label="字典名称">
              </el-table-column>
              <el-table-column
                prop="fieldname"
                label="字典类型"
                show-overflow-tooltip>
              </el-table-column>
              <!-- <el-table-column
                prop="txt"
                label="备注"
                show-overflow-tooltip>
              </el-table-column>
              <el-table-column
                prop="time"
                label="创建时间"
                show-overflow-tooltip>
              </el-table-column> -->
              <!-- <el-table-column label="操作">
                <template slot-scope="scope">
                  <el-button @click="editAndAddMaterial(scope.row)" type="text" size="small" icon="el-icon-edit">修改</el-button>
                  <el-button type="text" size="small" icon="el-icon-delete">删除</el-button>
                </template>
              </el-table-column> -->
            </el-table>
            <div class="block">
              <el-pagination
                background
                @current-change="handleCurrentChange"
                :current-page="currentPage4"
                layout="total, prev, pager, next, jumper"
                :total="total"
                hide-on-single-page>
              </el-pagination>
            </div>
          </el-tab-pane>
          <el-tab-pane label="信访类型" name="2">
            <el-table
              ref="multipleTable"
              :data="typeData"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="{textAlign:'center'}"
              :cell-style="{textAlign:'center'}">
              <el-table-column
                label="字典编号"
                width="120">
                <template slot-scope="scope">{{ scope.row.id }}</template>
              </el-table-column>
              <el-table-column
                prop="cardname"
                label="字典名称">
              </el-table-column>
              <el-table-column
                prop="fieldname"
                label="字典类型"
                show-overflow-tooltip>
              </el-table-column>
            </el-table>
            <div class="block">
              <el-pagination
                background
                @current-change="handleCurrentChange"
                :current-page="currentPage4"
                layout="total, prev, pager, next, jumper"
                :total="total"
                hide-on-single-page>
              </el-pagination>
            </div>
          </el-tab-pane>
          <el-tab-pane label="审核状态" name="3">
            <el-table
              ref="multipleTable"
              :data="auditData"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="{textAlign:'center'}"
              :cell-style="{textAlign:'center'}">
              <el-table-column
                label="字典编号"
                width="120">
                <template slot-scope="scope">{{ scope.row.id }}</template>
              </el-table-column>
              <el-table-column
                prop="visitstatusname"
                label="字典名称">
              </el-table-column>
            </el-table>
            <div class="block">
              <el-pagination
                background
                @current-change="handleCurrentChange"
                :current-page="currentPage4"
                layout="total, prev, pager, next, jumper"
                :total="total"
                hide-on-single-page>
              </el-pagination>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
      <el-dialog :title="dialogTitle" :visible.sync="dialogFormVisible" width="680px">
        <el-form :model="form">
          <el-form-item label="字典名称" :label-width="formLabelWidth">
            <el-input v-model="form.name" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="字典类型" :label-width="formLabelWidth">
            <el-input v-model="form.type" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="活动区域" :label-width="formLabelWidth">
            <el-select v-model="form.region" placeholder="请选择活动区域">
              <el-option label="区域一" value="shanghai"></el-option>
              <el-option label="区域二" value="beijing"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="备注" :label-width="formLabelWidth">
            <el-input v-model="form.txt" autocomplete="off"></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogFormVisible = false">取 消</el-button>
          <el-button type="primary" @click="makeSure">确 定</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import {getAllDict} from '@/utils/api'
export default {
  data(){
    return{
        vlaue1:'',
        options: [{
          value: '选项1',
          label: '黄金糕'
        }, {
          value: '选项2',
          label: '双皮奶'
        }, {
          value: '选项3',
          label: '蚵仔煎'
        }, {
          value: '选项4',
          label: '龙须面'
        }, {
          value: '选项5',
          label: '北京烤鸭'
        }],
        value: '',
        input: '',
        value2:'',
        tableData: [{
          date: '3',
          name: '王小虎',
          address: '上海市普陀区金沙江路 1518 弄',
          type:'hdijolkm',
          txt:'上海市普陀区金沙江路 1518 弄',
          time:'2022-04-29 08:53:38'
        }, {
          date: '2',
          name: '王小虎',
          address: '上海市普陀区金沙江路 1518 弄',
          type:'hdijolkm',
          txt:'上海市普陀区金沙江路 1518 弄',
          time:'2022-04-29 08:53:38'
        }, {
          date: '4',
          name: '王小虎',
          address: '上海市普陀区金沙江路 1518 弄',
          type:'hdijolkm',
          txt:'上海市普陀区金沙江路 1518 弄',
          time:'2022-04-29 08:53:38'
        }, {
          date: '1',
          name: '王小虎',
          address: '上海市普陀区金沙江路 1518 弄',
          type:'hdijolkm',
          txt:'上海市普陀区金沙江路 1518 弄',
          time:'2022-04-29 08:53:38'
        }, {
          date: '8',
          name: '王小虎',
          address: '上海市普陀区金沙江路 1518 弄',
          type:'hdijolkm',
          txt:'上海市普陀区金沙江路 1518 弄',
          time:'2022-04-29 08:53:38'
        }, {
          date: '6',
          name: '王小虎',
          address: '上海市普陀区金沙江路 1518 弄',
          type:'hdijolkm',
          txt:'上海市普陀区金沙江路 1518 弄',
          time:'2022-04-29 08:53:38'
        }, {
          date: '7',
          name: '王小虎',
          address: '上海市普陀区金沙江路 1518 弄',
          type:'hdijolkm',
          txt:'上海市普陀区金沙江路 1518 弄',
          time:'2022-04-29 08:53:38'
        }],
        typeData:[],
        auditData:[],
        multipleSelection: [],
        currentPage4: 1,
        total:0,
        dialogFormVisible: false,
        form: {
          name: '',
          region: '',
          date1: '',
          date2: '',
          delivery: false,
          type: [],
          resource: '',
          desc: '',
          type:'',
          txt:'',
          time:''
        },
        formLabelWidth: '120px',
        dialogTitle: '',
			  ifMaterialEdit: 0, // 0表示编辑，1表示新增
        activeName: '1'
    }
  },
  methods: {
    // 获取列表
    getdiclist(){
      getAllDict().then(res =>{
        // console.log('列表',res);
        this.tableData=res.data.data.docu1
        this.typeData = res.data.data.docu2
        this.auditData = res.data.data.visitDict
      })
    },
    handleSelectionChange(val) {
        this.multipleSelection = val;
        // console.log(this.multipleSelection);
    },
    handleSizeChange(val) {
        // console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
        // console.log(`当前页: ${val}`);
    },
    gohome(){
      this.$router.push({ path: '/home' })
    },
    makeSure() {
        let params = {
          xxxx: this.tableData.xxx,
          xxxx: this.tableData.xxx,
          xxxx: this.tableData.xxx,
        }
        // 新增接口对接
        if (this.ifMaterialEdit == 1) {
          // console.log('新增')
        } else if (this.ifMaterialEdit == 0) {
          // 编辑接口对接
          // console.log('编辑')
        }
        this.dialogFormVisible = false
    },
    editAndAddMaterial(row) {
        if (row) {
          this.ifMaterialEdit = 0
          this.dialogFormVisible = true
          this.dialogTitle = '编辑'
          // console.log(row)
          this.form.name=row.name
          this.form.type=row.type
          this.form.txt=row.txt
          this.form.time=row.time
        } else {
          this.ifMaterialEdit = 1
          this.dialogFormVisible = true
          this.dialogTitle = '新增'
        }
    },
  },
  mounted(){
    this.getdiclist()
  }
}
</script>

<style  lang="less" scoped>
.content{
  background: #fff;
  padding: 24px;
  .tit{
    display: flex;
    .box{
      margin-right: 24px;
      height: 36px;
    }
    .one{
      width: 140px;
    }
    .two{
      width: 189px;
    }
    .four{
      width: 130px;
    }
    .five{
      width: 287px;
    }
    .submit{
      width: 94px;
    }
  }
  .table{
    // margin-top: 25px;
    .block{
      margin-top: 27px;
    }
  }
}
.el-pagination {
    text-align: center; 
}
.el-table th.el-table__cell>.cell{
  text-align: center !important;
}
.el-table--enable-row-transition .el-table__body td.el-table__cell{
  text-align: center !important;
}
::v-deep .el-dialog__header{
  border-bottom: 1px solid #D8D8D8;
}
::v-deep .el-dialog__body .el-input__inner {
  width: 450px !important;
}
</style>